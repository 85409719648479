import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
	state: {
		mapinfo: null
	},
	getters: {
		mapinfo: state => state.mapinfo
	},
	
	mutations: {
		mapinfo (state, mapinfo = null){
			state.mapinfo = mapinfo
		}
	},
	actions: {
		
	}
})

