import moment from "dayjs";

/**
 * @name: handleCutZero
 * @description:  去掉double类型小数点后面多余的0
 参数：old 要处理的字符串或double
 返回值：newStr 没有多余零的小数或字符串
 * @param {*} num
 * @return {*}num
 */
export function parseFloatDesc(num) {
    num = Number(num)
    return parseFloat(num);
}

/**
 * 获取图表数据显示的当前月份
 * @returns {number}
 */
export function getChartCurMonth() {
  let curMonth = moment().month() + 1
  const curDay = moment().date()
  if (curDay < 20) {
    curMonth = curMonth - 1
  }
  return curMonth
}
