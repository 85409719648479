import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import './utils'
import ElementUI from 'element-ui'
import './assets/css/index.scss'
// import './assets/css/index.scss'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import { parseFloatDesc } from '@/utils/common'

import notifyMessage from '@/utils/notifyMessage/index'
import vue from "vue";
Vue.use(notifyMessage.register)

vue.prototype.$myMsg = parseFloatDesc
Vue.config.productionTip = false

new Vue({
	store,
	router,
  render: h => h(App),
}).$mount('#app')
