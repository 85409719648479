<template>
  <div id="app">
		<router-view></router-view>
  </div>
</template>

<script>
	export default {
		name: 'App'
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		/* margin-top: 60px; */
	}

	@font-face {
	  font-family: SSruiyuanTi;
	  src: url("~@/assets/font/上首锐圆体.ttf");
	}
	@font-face {
	  font-family: PangMenZhengDao;
	  src: url("~@/assets/font/庞门正道标题体.ttf");
	}
	@font-face {
	  font-family: JingDianZhunHei;
	  src: url("~@/assets/font/经典准黑简体.TTF");
	}
	@font-face {
	  font-family: SourceHanSansCN-Bold;
	  src: url("~@/assets/font/SourceHanSansCN-Bold.otf");
	}
	@font-face {
	  font-family: SourceHanSansCN-Medium;
	  src: url("~@/assets/font/SourceHanSansCN-Medium.otf");
	}
	@font-face {
	  font-family: SourceHanSansCN-Regular;
	  src: url("~@/assets/font/SourceHanSansCN-Regular.otf");
	}
  @font-face {
    font-family: YouSheBiaoTiHei;
    src: url("~@/assets/font/优设标题黑.TTF");
  }




</style>
